/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import NewsletterSubscribeForm from 'components/subscribe-form';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "En un buen día, programar se siente como magia."
    }), "\n", _jsx(_components.p, {
      children: "Tú y tu editor de código están en sincronía."
    }), "\n", _jsx(_components.p, {
      children: "“De verdad funciona” te dices a ti mismo en silencio."
    }), "\n", _jsx(_components.p, {
      children: "Excepto que no todos los días son así. Te sucede el viernes antes de irte a casa. Te pasa en una sesión de programación con tus compañeros."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Un error que no puedes explicar"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Lees el código una y otra vez, pero no puedes detectar el error. Intentas diferentes maneras para solucionarlo. Mueves una línea de código acá y otra allá. Intentas revisar el artículo o el videotutorial otra vez. Lo buscas en stackoverflow. Nada."
    }), "\n", _jsx(_components.p, {
      children: "Tu confianza disminuye con cada intento. ¿Por qué me toma horas corregir un error que otros solucionan en pocos minutos?"
    }), "\n", _jsx(_components.p, {
      children: "Te preguntas."
    }), "\n", _jsx(_components.p, {
      children: "Una voz en tu cabeza se vuelve imposible de ignorar."
    }), "\n", _jsx(_components.p, {
      children: "“No sé lo que estoy haciendo”"
    }), "\n", _jsx(_components.p, {
      children: "Esta experiencia se repite una y otra vez. Esto revela una brecha seria en tu conocimiento. Una brecha que no puedes seguir ignorando. No, si quieres seguir avanzando en tu carrera."
    }), "\n", _jsx(_components.h2, {
      children: "El Problema es sencillo"
    }), "\n", _jsx(_components.p, {
      children: "​​La fuente de tu dolor tiene que ver con que:"
    }), "\n", _jsx(_components.p, {
      children: "No tuviste un currículo estructurado desde el principio."
    }), "\n", _jsx(_components.p, {
      children: "No tuviste un currículum que te ayudará a construir el dominio de los fundamentos."
    }), "\n", _jsx(_components.p, {
      children: "No tuviste un currículum que te ayudará realmente a comprender cómo funcionan las abstracciones."
    }), "\n", _jsx(_components.p, {
      children: "Revisaste los videotutoriales, artículos, y los cursos para aprender React o cualquier otro framework nuevo del mes. Pero ninguno de esos recursos te ayudo a crear las representaciones mentales esenciales para resolver problemas de ingeniería."
    }), "\n", _jsx(_components.p, {
      children: "¡No es tu culpa!"
    }), "\n", _jsx(_components.p, {
      children: "La falta de efectividad de esos recursos tiene que ver con que son pasivos. Te ponen videos frente a ti con la expectativa de que los veas y consumas, como si fuera Netflix."
    }), "\n", _jsx(_components.h2, {
      children: "“Netflix para nerds” no es lo que tu necesitas"
    }), "\n", _jsx(_components.p, {
      children: "Tú necesitas experiencias auténticas de aprendizaje que te esfuercen. Experiencias que te expongan a la complejidad de los problemas reales que te vas a enfrentar en bases de códigos de producción."
    }), "\n", _jsxs(_components.p, {
      children: ["Tu necesitas entrenamiento profesional diseñado para ayudarte a experimentar con temas complejos. ", _jsx(_components.strong, {
        children: "En el esfuerzo y experimento, con un soporte adecuado, es donde sucede el aprendizaje"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Eso es lo que vas a encontrar en Escuela Frontend."
    }), "\n", _jsx(_components.h2, {
      children: "Entrenamiento de Ingeniería Front-End Profesional"
    }), "\n", _jsx(_components.p, {
      children: "La clave para adquirir los conocimientos y las habilidades que duran toda una carrera profesional es centrarse en los fundamentos."
    }), "\n", _jsx(_components.p, {
      children: "Por eso diseñamos cada curso con la siguiente filosofía:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Los fundamentos primero, los fundamentos lo son todo."
      })
    }), "\n", _jsx(_components.p, {
      children: "Fundamentos no quiere decir contenido para principiantes, sino que se enfoca en comprender e internalizar las estrategias y patrones esenciales que te ayudan a entender los problemas, no las soluciones."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Ejercicios optimizados para la retención del conocimiento."
      })
    }), "\n", _jsx(_components.p, {
      children: "No tiene caso aprender algo si no vas a poder aplicarlo a otros contextos. La manera de obtener esta habilidad es exponerte a los problemas primero y luego a la instrucción. Por eso los ejercicios son la base de nuestros cursos."
    }), "\n", _jsx("div", {
      className: "not-prose py-20",
      children: _jsx(NewsletterSubscribeForm, {})
    }), "\n", _jsx(_components.p, {
      children: "No estamos tratando de enseñarte el nuevo framework o la nueva librería que acaba de salir. Estamos tratando de enseñarte cosas que serán útiles en las próximas décadas, como un enfoque sistemático de resolución de problemas o aprender a deconstruir un lenguaje de programación."
    }), "\n", _jsx(_components.p, {
      children: "Estamos acá para ayudarte a avanzar en tu carrera profesional."
    }), "\n", _jsx(_components.p, {
      children: "Cuando te unes a Escuela Frontend tendrás acceso a los siguientes recursos como parte de tu entrenamiento."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
